import { useState, useEffect, useContext } from "react";
import { ComboBox, IComboBox, IComboBoxOption, IComboBoxStyles, Stack, TextField } from "@fluentui/react";
import { Button, Tooltip, useFieldContextValues_unstable } from "@fluentui/react-components";
import { Send28Filled } from "@fluentui/react-icons";
import { useTranslation } from "react-i18next";

import styles from "./QuestionInput.module.css";
import { SpeechInput } from "./SpeechInput";
import { LoginContext } from "../../loginContext";
import { requireLogin } from "../../authConfig";
import React from "react";
import { countries } from "./countries";

interface Props {
    onSend: (question: string) => void;
    disabled: boolean;
    initQuestion?: string;
    placeholder?: string;
    clearOnSend?: boolean;
    showSpeechInput?: boolean;
    selectedCountry: string;
    setSelectedCountry: React.Dispatch<React.SetStateAction<string>>;
}

export const QuestionInput = ({ onSend, disabled, placeholder, clearOnSend, initQuestion, showSpeechInput, selectedCountry, setSelectedCountry }: Props) => {
    const [question, setQuestion] = useState<string>("");
    const { loggedIn } = useContext(LoginContext);
    const { t } = useTranslation();
    const [isComposing, setIsComposing] = useState(false);

    const countryOptions: IComboBoxOption[] = countries.sort((a, b) => a.localeCompare(b)).map(c => ({ key: c, text: c }));

    const countryComboBoxRef = React.useRef<IComboBox>(null);

    useEffect(() => {
        initQuestion && setQuestion(initQuestion);
    }, [initQuestion]);

    const sendQuestion = () => {
        if (disabled || !question.trim() || !selectedCountry) {
            return;
        }

        onSend(question);

        if (clearOnSend) {
            setQuestion("");
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (isComposing) return;

        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    const handleCompositionStart = () => {
        setIsComposing(true);
    };
    const handleCompositionEnd = () => {
        setIsComposing(false);
    };

    const onSelectedCountryChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (option) {
            setSelectedCountry(option.key.toString());
        }
    };

    const onQuestionChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (!newValue) {
            setQuestion("");
        } else if (newValue.length <= 1000) {
            if (newValue?.toLowerCase() == "eq1") {
                newValue = "How do you treat a delaminated rotator cuff tear?";
            } else if (newValue?.toLowerCase() == "eq2") {
                newValue = "Cuáles son los aspectos clave de las ventas de la terminal i90?";
            }

            setQuestion(newValue);
        }
    };

    const disableRequiredAccessControl = requireLogin && !loggedIn;
    const sendQuestionDisabled = disabled || !question.trim() || !selectedCountry || requireLogin;

    if (disableRequiredAccessControl) {
        placeholder = "Please login to continue...";
    }

    return (
        <Stack className={styles.questionInputContainer}>
            <Stack horizontal>
                <TextField
                    className={styles.questionInputTextArea}
                    disabled={disableRequiredAccessControl}
                    placeholder={placeholder}
                    multiline
                    resizable={false}
                    borderless
                    value={question}
                    onChange={onQuestionChange}
                    onKeyDown={onEnterPress}
                    onCompositionStart={handleCompositionStart}
                    onCompositionEnd={handleCompositionEnd}
                />
                <ComboBox
                    componentRef={countryComboBoxRef}
                    selectedKey={selectedCountry}
                    onChange={onSelectedCountryChange}
                    label="Country"
                    options={countryOptions}
                    dropdownWidth={100}
                />
                <div className={styles.questionInputButtonsContainer}>
                    <Tooltip content={t("tooltips.submitQuestion")} relationship="label">
                        <Button
                            size="large"
                            icon={<Send28Filled primaryFill="rgba(115, 118, 225, 1)" />}
                            disabled={sendQuestionDisabled}
                            onClick={sendQuestion}
                        />
                    </Tooltip>
                </div>
                {showSpeechInput && <SpeechInput updateQuestion={setQuestion} />}
            </Stack>
        </Stack>
    );
};
