import { useContext } from "react";
import { Outlet, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./Layout.module.css";

import { useLogin } from "../../authConfig";

import { LoginButton } from "../../components/LoginButton";
import { MessageBar, MessageBarType } from "@fluentui/react";
import { LoginContext } from "../../loginContext";

const Layout = () => {
    const { t } = useTranslation();

    const { loggedIn, setLoggedIn } = useContext(LoginContext);

    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer}>
                    <Link to="/" className={styles.headerTitleContainer}>
                        <h3 className={styles.headerTitle}>{t("headerTitle")}</h3>
                    </Link>
                    <div className={styles.loginMenuContainer}>{useLogin && <LoginButton />}</div>
                </div>
            </header>

            {(!useLogin || loggedIn) && <Outlet />}
            {useLogin && !loggedIn && (
                <MessageBar delayedRender={false} role="none" messageBarType={MessageBarType.warning}>
                    Please login first.
                </MessageBar>
            )}
        </div>
    );
};

export default Layout;
