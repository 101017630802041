import { useEffect, useRef } from "react";
import cloudinary from "cloudinary-video-player";
import "cloudinary-video-player/cld-video-player.min.css";

// Define the types for the props
interface VideoPlayerProps {
    id: string;
    publicId: string;
    offsetSecs: number;
    playerConfig?: Record<string, any>; // Assuming playerConfig is an object with any key-value pairs, otherwise specify more precise type
    sourceConfig?: Record<string, any>; // Same assumption as playerConfig
    [propName: string]: any; // This allows any number of additional props
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ id, publicId, playerConfig, sourceConfig, ...props }) => {
    // Specify the types for the refs
    const cloudinaryRef = useRef<typeof cloudinary>();
    const playerRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (cloudinaryRef.current) return;

        cloudinaryRef.current = cloudinary;

        let cur = playerRef as any;

        const player = cloudinaryRef.current.videoPlayer(cur.current, {
            cloud_name: "arthrex-production",
            private_cdn: true,
            secure: true,
            secure_distribution: "cdn.arthrex.io",
            controls: true,
            ...playerConfig
        });
        player.source(publicId, sourceConfig);

        console.log("OFFSET ", props.offsetSecs);
        player.currentTime(props.offsetSecs);
    }, [publicId, sourceConfig, playerConfig]); // It's a good practice to include all dependencies

    return <video ref={playerRef} id={id} className="cld-video-player cld-fluid" {...props} />;
};

export default VideoPlayer;
