import { Person24Regular, Sparkle24Regular } from "@fluentui/react-icons";
import { AuthorType } from "./AuthorType";
import styles from "./AuthorName.module.css";
import { Stack } from "@fluentui/react";

interface Props {
    type: AuthorType;
}

export const AuthorName = ({ type }: Props) => {
    const label = type === AuthorType.User ? AuthorNames.user : AuthorNames.ai;

    return (
        <Stack horizontal className={styles.container}>
            {type === AuthorType.Ai && <Sparkle24Regular primaryFill="#0575D6" />}
            <div className={styles.message}>{label}</div>
            {type === AuthorType.User && <Person24Regular primaryFill="#0575D6" />}
        </Stack>
    );
};

export const AuthorNames = {
    user: "Me",
    ai: "Arthrex ResearchAI"
};
